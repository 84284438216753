import { ReactQueryDevtools } from '@smu-chile/pkg-unimarc-hooks/reactQuery/devtools'
import {
  QueryClient,
  QueryClientProvider
} from '@smu-chile/pkg-unimarc-hooks/reactQuery'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import '../styles/globals.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/tokens.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/globals.css'
import { Layout } from 'components/Layout'
import CurtainWrapper from 'components/CurtainWrapper'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps): React.ReactElement {
  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places`}
        strategy='beforeInteractive'
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', 'GTM-TSJPRBR');`
        }}
        strategy='afterInteractive'
      />
      <QueryClientProvider client={queryClient}>
        <CurtainWrapper>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </CurtainWrapper>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  )
}

export default MyApp
