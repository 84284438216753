import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Column,
  Container,
  Icon,
  Overlay,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  goToHome,
  useSession,
  useContentful,
  logoutV2,
  getParamsInURL,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { DropDownMyProfile } from '@smu-chile/pkg-unimarc-components/stories/organisms/Header/MyProfile/DropDownMyProfile'
import Cookies from 'js-cookie'
import { variableBreakdownCF } from './helper'
import useScrollListener from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import { shouldOpenModal } from '../Header/helper/shouldOpenModal'
import { LOGIN_MODAL_IMAGE, MODAL_LOGOUT_STRAWBERRY } from 'shared/constants'
import stylesHeader from './Header.module.css'
import { HeaderMembership } from './components/HeaderMembership'
import { HeaderClubBenefitsModal } from './components/HeaderClubBenefitsModal'
import { HeaderClubSavingModal } from './components/HeaderClubSavingModal'

export const Header = (): React.ReactElement => {
  const router = useRouter()
  const isParamsApp = getParamsInURL('source')
  const { isLoggedIn, isLoading } = useSession()
  const shouldLoginModalBeOpen = shouldOpenModal(
    router.isReady,
    router.query.login,
    isLoading,
    isLoggedIn
  )
  const [showMembershipMenu, setShowMembershipMenu] = useState(true)

  const { data: dataMembership, isLoading: isLoadingMembership } =
    useUserWithMembership()

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [isVisibleModalLogout, setIsVisibleModalLogout] = useState(false)
  const [isVisibleMyProfile, setIsVisibleMyProfile] = useState(false)

  const scroll = useScrollListener()
  const primary = useRef(null)

  const site = 'unimarc'

  const loginImageData = useContentful({
    id_contentful: 'login_modal',
    options: {
      'sys.id': LOGIN_MODAL_IMAGE
    },
    type: 'assets'
  })
  const { data: dataUser } = useUserWithMembership()

  const userFirstName = dataUser?.firstName ?? 'Mi perfil'

  const loginImageUrl = loginImageData.data?.['items']?.[0].fields.file.url

  const handleCloseLoginModal = async () => {
    setLoginModalOpen(false)
    if (!isLoggedIn) router.push(process.env.NEXT_PUBLIC_HOMEURL || '/')
    await router.push(
      {
        query: {
          ...router.query,
          login: undefined,
          ['forgot-password']: false,
          step: undefined
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const { cfDataHeader } = variableBreakdownCF()

  const imgModalLogout = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-logout',
    options: {
      'sys.id': MODAL_LOGOUT_STRAWBERRY[0]
    },
    type: 'assets'
  })

  const imageModalLogout =
    imgModalLogout?.data?.['items'][0]?.fields?.file?.url || ''

  const handleClickGoToMyCoupons = async () => {
    await router.push(process.env.NEXT_PUBLIC_COUPONSURL)
    setIsVisibleMyProfile(false)
  }

  const handleLogout = () => {
    Cookies.remove('addToCart')
    logoutV2()
  }

  const handleClickGoToMyLists = async () => {
    await router.push(process.env.NEXT_PUBLIC_LISTURL || '/lists')
    setIsVisibleMyProfile(false)
  }

  const handleClickGoToMyCards = async () => {
    await router.push(process.env.NEXT_PUBLIC_CARDSURL || '/MyCards')
    setIsVisibleMyProfile(false)
  }

  const handleClickGoToMyOrders = async () => {
    if (window.location.pathname === '/') window.location.reload()
    if (window.location.pathname !== '/') router.push('/')
    setIsVisibleMyProfile(false)
  }
  const handleClickGoToMyPersonalInformation = async () => {
    await router.push(
      process.env.NEXT_PUBLIC_INFORMATIONURL || '/PersonalInformations'
    )
    setIsVisibleMyProfile(false)
  }
  const handleClickGoToMyMemberShip = async () => {
    await router.push(process.env.NEXT_PUBLIC_MEMBERSHIPURL || '/Membership')
    setIsVisibleMyProfile(false)
  }

  const onClickGoToHelpCenter = async () => {
    await router.push(process.env.NEXT_PUBLIC_HELPCENTER_URL || '/HelpCenter')
    setIsVisibleMyProfile(false)
  }

  const onClickGoToMyRequest = async () => {
    await router.push(
      process.env.NEXT_PUBLIC_MY_REQUESTS_ZENDESK_URL || '/MyRequestsZendesk'
    )
    setIsVisibleMyProfile(false)
  }

  const onClickGoToMyFavorites = async () => {
    await router.push('/MyFavorites')
    setIsVisibleMyProfile(false)
  }

  const callGoToHome = () => {
    window.location.href = goToHome()
  }

  const handleClose = () => {
    if (isVisibleMyProfile) setIsVisibleMyProfile(false)
  }

  useEffect(() => {
    setLoginModalOpen(shouldLoginModalBeOpen)
  }, [shouldLoginModalBeOpen])

  useEffect(() => {
    if (scroll.y > scroll.lastY) {
      primary?.current?.classList?.add(stylesHeader['hide-menu'])
    } else {
      primary?.current?.classList?.remove(stylesHeader['hide-menu'])
    }
  }, [scroll.y, scroll.lastY])

  useEffect(() => {
    if (
      dataMembership?.error === 'not found' ||
      dataMembership?.status === 404 ||
      dataMembership?.status === 400 ||
      dataMembership?.status.toString() === '404' ||
      dataMembership?.status.toString() === '400' ||
      (!dataMembership?.membership && !isLoadingMembership)
    ) {
      setShowMembershipMenu(false)
    }
  }, [dataMembership, isLoadingMembership])

  return (
    <>
      <Container
        alignItems='center'
        customClassName={stylesHeader['main-header']}
        justifyContent='center'
        minHeight='74px'
        position='sticky'
        ref={primary}
        role='header'
        tagName='header'
        zIndex={getGlobalStyle('--z-index-10')}
      >
        <LoginModalBrowse
          imageSrc={loginImageUrl}
          isOpen={loginModalOpen}
          onClose={handleCloseLoginModal}
          router={router}
        />
        <BigScreen>
          <Column maxWidth={getGlobalStyle('--width-max-desktop')}>
            <Row
              alignItems='center'
              justifyContent='between'
            >
              <Row onClick={callGoToHome}>
                <Icon
                  clickable='pointer'
                  customSize={151}
                  name='LogoHeaderUnimarcCL'
                  sizes='xl'
                />
              </Row>
              {isLoggedIn && (
                <Row
                  alignItems='center'
                  justifyContent='end'
                  maxWidth='300px'
                  position='relative'
                >
                  <Container
                    alignItems='center'
                    borderRadius={getGlobalStyle('--border-radius-xl')}
                    className={stylesHeader.buttonHover}
                    clickable='pointer'
                    justifyContent='center'
                    minHeight='40px'
                    onClick={() => {
                      setIsVisibleMyProfile(!isVisibleMyProfile)
                    }}
                    tagName='section'
                    width={154}
                  >
                    <Icon
                      clickable='pointer'
                      color={getGlobalStyle('--color-text-gray-light')}
                      customSize={24}
                      name='UserOutline'
                    />
                    <Spacer.Vertical size={8} />
                    <Text
                      clickable='pointer'
                      customColor={getGlobalStyle('--color-text-gray-light')}
                      fontSize='md'
                      fontWeight='medium'
                      truncate={10}
                    >
                      {userFirstName}
                    </Text>
                  </Container>
                  {isVisibleMyProfile && (
                    <>
                      <span className={stylesHeader.popupCorner}></span>
                      <DropDownMyProfile
                        cfDataHeader={cfDataHeader}
                        customClassName={stylesHeader.customPosition}
                        onClickCloseMyProfile={() => {
                          setIsVisibleMyProfile(false)
                        }}
                        onClickGoToHelpCenter={onClickGoToHelpCenter}
                        onClickGoToMyCards={handleClickGoToMyCards}
                        onClickGoToMyCoupons={handleClickGoToMyCoupons}
                        onClickGoToMyFavorites={onClickGoToMyFavorites}
                        onClickGoToMyLists={handleClickGoToMyLists}
                        onClickGoToMyMemberShip={handleClickGoToMyMemberShip}
                        onClickGoToMyOrders={handleClickGoToMyOrders}
                        onClickGoToMyPersonalInformation={
                          handleClickGoToMyPersonalInformation
                        }
                        onClickGoToMyRequest={onClickGoToMyRequest}
                        onClickLogout={handleLogout}
                        onClickLogoutMobile={() => {
                          return setIsVisibleModalLogout(true)
                        }}
                        showMembershipMenu={showMembershipMenu}
                        site={site}
                      />
                    </>
                  )}
                </Row>
              )}
            </Row>
          </Column>
        </BigScreen>
        <SmallScreen>
          <Column
            customWidth='93'
            maxWidth={getGlobalStyle('--width-max-mobile')}
            padding='4px 0'
          >
            <Row
              alignItems='center'
              justifyContent='between'
            >
              <Row onClick={callGoToHome}>
                <Icon
                  clickable='pointer'
                  customSize={114}
                  name='LogoHeaderUnimarcCL'
                  sizes='lg'
                />
              </Row>
              {(isLoggedIn || isParamsApp) && (
                <Row justifyContent='end'>
                  <Container
                    alignItems='center'
                    border={`1px solid ${getGlobalStyle(
                      '--color-neutral-black2'
                    )}`}
                    borderRadius={getGlobalStyle('--border-radius-xl')}
                    clickable='pointer'
                    justifyContent='center'
                    minHeight='36px'
                    onClick={() => {
                      setIsVisibleMyProfile(!isVisibleMyProfile)
                    }}
                    tagName='section'
                    width={118}
                  >
                    <Icon
                      clickable='pointer'
                      color={getGlobalStyle('--color-neutral-black2')}
                      customSize={24}
                      name='UserOutline'
                    />
                    <Spacer.Vertical size={8} />
                    <Text
                      customColor={getGlobalStyle('--color-neutral-black2')}
                      fontSize='md'
                      fontWeight='medium'
                      truncate={7}
                    >
                      {userFirstName}
                    </Text>
                  </Container>
                  <DropDownMyProfile
                    cfDataHeader={cfDataHeader}
                    imageModalLogout={imageModalLogout}
                    isVisibleModalLogout={isVisibleModalLogout}
                    isVisibleMyProfile={isVisibleMyProfile}
                    onClickCloseLogoutMobile={() => {
                      setIsVisibleModalLogout(false)
                    }}
                    onClickCloseMyProfile={() => {
                      setIsVisibleMyProfile(false)
                    }}
                    onClickGoToHelpCenter={onClickGoToHelpCenter}
                    onClickGoToMyCards={handleClickGoToMyCards}
                    onClickGoToMyCoupons={handleClickGoToMyCoupons}
                    onClickGoToMyFavorites={onClickGoToMyFavorites}
                    onClickGoToMyLists={handleClickGoToMyLists}
                    onClickGoToMyMemberShip={handleClickGoToMyMemberShip}
                    onClickGoToMyOrders={handleClickGoToMyOrders}
                    onClickGoToMyPersonalInformation={
                      handleClickGoToMyPersonalInformation
                    }
                    onClickGoToMyRequest={onClickGoToMyRequest}
                    onClickLogout={handleLogout}
                    onClickLogoutMobile={() => {
                      return setIsVisibleModalLogout(true)
                    }}
                    prependSlot={
                      <>
                        <HeaderMembership />
                      </>
                    }
                    showMembershipMenu={showMembershipMenu}
                    site={site}
                  />
                </Row>
              )}
            </Row>

            <HeaderClubBenefitsModal />
            <HeaderClubSavingModal />
          </Column>
        </SmallScreen>
      </Container>
      <BigScreen>
        {isVisibleMyProfile && <Overlay onClick={handleClose} />}
      </BigScreen>
    </>
  )
}
