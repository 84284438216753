import { DAYS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'
import { IOrders } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrders'
import { timeUTCFilter } from 'shared/helpers/timeUTCFilter'

const withoutHours = (date: Date) => {
  return new Date(date).setHours(0, 0, 0, 0)
}

export const shippingDate = (order: IOrders | Order): string => {
  try {
    const {
      addressType,
      status,
      deliveryWindow: { startDateUtc, endDateUtc }
    } = order

    const startDate = new Date(startDateUtc)
    const endDate = new Date(endDateUtc)
    const arrivedText = addressType === 'residential' ? 'Llegó el' : ''
    const arrivesText = addressType === 'residential' ? 'Llega el' : ''
    const arrivesTodayText = addressType === 'residential' ? 'Llega' : ''

    if (
      withoutHours(endDate) === withoutHours(new Date()) &&
      status !== 'delivered'
    ) {
      return `${arrivesTodayText} Hoy, ${timeUTCFilter(
        startDate
      )} - ${timeUTCFilter(endDate)} hrs.`
    }

    if (endDate < new Date() && status === 'delivered') {
      return `${arrivedText} ${DAYS[startDate.getDay()]} ${
        startDate.getDate().toString().length < 2
          ? '0' + startDate.getDate()
          : startDate.getDate()
      }/${('0' + (startDate.getMonth() + 1)).slice(-2)}`
    }

    return `${arrivesText} ${DAYS[startDate.getDay()]} ${
      startDate.getDate().toString().length < 2
        ? '0' + startDate.getDate()
        : startDate.getDate()
    }/${('0' + (startDate.getMonth() + 1)).slice(-2)}`
  } catch (error) {
    console.log(`Error file shared/helpers/shippingDate.ts : ${error}`)
    return ''
  }
}
