import { getQuantityOfProducts } from './getQuantityOfProducts'

export const calculateProductsAmount = (selectedList) => {
  const rawQuantityOfProducts = selectedList?.products?.map( (item) => {
    let totalQuantity = 0
    const weighableProduct = item.measurenmentUnit === 'kg'
    if (weighableProduct) {
      totalQuantity = 1
    } else {
      const quantity = getQuantityOfProducts(item?.id, [...selectedList.products])
      totalQuantity = quantity.reduce((a, b) => {return a + b}, 0)
    }
    return totalQuantity
  })
  const quantityOfProducts = rawQuantityOfProducts?.reduce((a, b) => {return a + b}, 0)

  return quantityOfProducts
}