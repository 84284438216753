import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { IHandleStatusTracking, IWebTracking } from '../interfaces/IShipping'
import { statusRequestReceivedTracking } from './statusRequestReceivedTracking'
import { switchStatusTracking } from './switchStatusTracking'

export const changeStatus = (icons: IWebTracking[], order: IOrder): IHandleStatusTracking[] => {
  const { shippingData, status } = order
  
  const iconPicking = shippingData?.address?.addressType === 'residential' ? 'Motorcycle' : 'Shop'
  const textPosition3 = shippingData?.address?.addressType === 'residential' ? 'En camino' : 'Listo para retiro'

  const statusRequestReceived = [
    'waiting-for-sellers-confirmation',
    'payment-pending',
    'payment-approved',
    'time-for-cancelation',
    'on_order_completed',
    'order_created',
    'approve_payment',
    'authorize-fulfillment'
  ]

  if (statusRequestReceived.includes(status.toLowerCase())) {
    return statusRequestReceivedTracking({
      iconPicking,
      icons,
      textPosition3
    })
  }

  return switchStatusTracking({
    iconPicking,
    icons,
    shippingData,
    status,
    textPosition3
  })

}
