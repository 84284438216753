import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  ContentfulResponse,
  IContentfulResponse,
  currencyFormat
} from '@smu-chile/pkg-unimarc-hooks'
import { ConnectionCfHeaderMenu } from '../../../shared/utils/connectionCfHeaderMenu'
import { IUserWithMembershipResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetUserWithMembership'

const MEMBERSHIP_ACTIONS_BLOCK_IDS = ['Botones headers']
const MEMBERSHIP_ACTIONS_BENEFITS_LABELS = ['Mis beneficios']
const MEMBERSHIP_ACTIONS_SAVING_LABELS = ['Ahorrado']
const MEMBERSHIP_BANNER_BLOCK_IDS = ['Botones Membresia']
const MEMBERSHIP_BLOCK_IDS = ['Headers']
const MEMBERSHIP_LEVEL_PICTURE_CONTENT_TYPE_IDS = ['banner']

const MEMBERSHIP_LEVELS_BLOCK_IDS = {
  clubunimarc: 'Socio Club',
  diamante: 'Diamante',
  oro: 'Socio Oro',
  platino: 'Socio Platino',
  default: 'Sin nivel'
}

export type VariableBreakdownCfMembershipProps = {
  onBenefitsClick?: VoidFunction
  onSavingClick?: VoidFunction
  userSavingsTotal?: number
  userWithMembership?: IUserWithMembershipResponse
}

export const variableBreakdownCfMembership = ({
  onBenefitsClick,
  onSavingClick,
  userSavingsTotal,
  userWithMembership
}: VariableBreakdownCfMembershipProps) => {
  const { dataHeaderCF } = ConnectionCfHeaderMenu({ include: 6 })
  const cfResponse = new ContentfulResponse(dataHeaderCF as IContentfulResponse)
  const cfPopulate = cfResponse.populateEntries().getResponse()

  const [headers] = cfPopulate?.items ?? []

  const [membershipActionBlocks] = searchMembershipActions(headers?.items ?? [])
  const [membershipBlocks] = searchMemberships(headers?.items ?? [])
  const [bannerBlocks] = searchBanner(headers?.items ?? [])

  const userLevel = userWithMembership?.level ?? 'default'
  const userMembership = userWithMembership?.membership ?? null

  const [levelBlock] = searchLevel(membershipBlocks?.['items'] ?? [], userLevel)
  const [levelPictureBlock] = searchLevelPicture(levelBlock?.['items'] ?? [])

  const saving = userSavingsTotal ?? '$-'

  const actions = mapActions(membershipActionBlocks?.['items'] ?? [], {
    onBenefitsClick,
    onSavingClick,
    saving
  })
  const banners = mapBanners(bannerBlocks?.['items'] ?? [], userMembership)

  const backgroundColor =
    levelBlock?.['backgroundColor'] ?? getGlobalStyle('--color-background-gray')
  const firstName = userWithMembership?.firstName ?? ''
  const fontColor =
    levelBlock?.['fontColor'] ?? getGlobalStyle('--color-text-black')
  const level = levelBlock?.['description'] ?? ''
  const pictureSrc =
    levelPictureBlock?.['imgMobile']?.['fields']?.['file']?.['url'] ?? null

  return {
    actions,
    backgroundColor,
    banners,
    firstName,
    fontColor,
    level,
    pictureSrc
  }
}

export const mapActions = (
  items: unknown[],
  {
    onBenefitsClick,
    onSavingClick,
    saving
  }: {
    onBenefitsClick?: VoidFunction
    onSavingClick?: VoidFunction
    saving?: string | number
  }
) => {
  return items.map((item) => {
    let onClick = null
    let text = item?.['label']

    if (MEMBERSHIP_ACTIONS_BENEFITS_LABELS.includes(text)) {
      onClick = onBenefitsClick
    }

    if (MEMBERSHIP_ACTIONS_SAVING_LABELS.includes(text)) {
      onClick = onSavingClick

      text = `${text}\n ${
        isNaN(Number(saving))
          ? saving
          : currencyFormat({ number: saving, bool: false })
      }`
    }

    return {
      id: item?.['sys']?.['id'],
      onClick: onClick,
      pictureSrc: item?.['icon']?.['fields']?.['file']?.['url'] ?? null,
      text
    }
  })
}

export const mapBanners = (items: unknown[], membership?: string) => {
  return items
    .filter((item) => {
      if (membership) {
        if (membership !== 'active') {
          return item?.['label'] === 'Renovar'
        }
      } else {
        return item?.['label'] === 'Ser diamante'
      }

      return false
    })
    .map((item) => {
      return {
        buttonLabel: item?.['buttonText'],
        id: item?.['sys']?.['id'],
        label: item?.['labelNewText'],
        labelBackgroundColor: item?.['labelColorBackground'],
        labelFontColor: item?.['labelColorText'],
        pictureSrc: item?.['icon']?.['fields']?.['file']?.['url'] ?? null,
        showButton: item?.['showButton'],
        showLabel: item?.['showLabel'],
        subtitle: item?.['subtitulo2'],
        title: item?.['subtitulo']
      }
    })
}

export const searchBanner = (items: unknown[]) => {
  return items.filter((item) => {
    return MEMBERSHIP_BANNER_BLOCK_IDS.includes(item?.['blockId'])
  })
}

export const searchLevel = (items: unknown[], level?: string) => {
  return items.filter((item) => {
    return [MEMBERSHIP_LEVELS_BLOCK_IDS[level]].includes(item?.['blockId'])
  })
}

export const searchLevelPicture = (items: unknown[]) => {
  return items.filter((item) => {
    return MEMBERSHIP_LEVEL_PICTURE_CONTENT_TYPE_IDS.includes(
      item?.['sys']?.['contentType']?.['sys']?.['id']
    )
  })
}

export const searchMembershipActions = (items: unknown[]) => {
  return items.filter((item) => {
    return MEMBERSHIP_ACTIONS_BLOCK_IDS.includes(item?.['blockId'])
  })
}

export const searchMemberships = (items: unknown[]) => {
  return items.filter((item) => {
    return MEMBERSHIP_BLOCK_IDS.includes(item?.['blockId'])
  })
}
