import classNames from 'classnames'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  DesktopModalWrapper,
  RichText
} from '@smu-chile/pkg-unimarc-components'
import styles from './InnerModal.module.css'

interface InnerModalProps {
  contentType?: string
  isMobile?: boolean
  isOpen?: boolean
  onClose?: () => void
  richTextData?: Array<object>
}

export const InnerModal = ({
  contentType,
  isMobile,
  isOpen,
  onClose,
  richTextData
}: InnerModalProps) => {
  const title = () => {
    if (contentType === 'tyc') return 'Términos y condiciones'
    return 'Políticas de privacidad'
  }
  return (
    <DesktopModalWrapper
      blockId={getBemId('term-and-conditions', 'container')}
      bussinessProps={{
        site: 'unimarc'
      }}
      dragProps={{
        draggButton: false
      }}
      footerChildrenProps={{
        default: true,
        buttonDefaultProps: {
          label: 'Entiendo',
          maxWidth: '343px',
          onClick: onClose
        }
      }}
      hasfooter={true}
      headerCloseIcon={{
        default: true,
        name: 'CloseThin',
        sizes: '2xs'
      }}
      headerLeft={false}
      headerTitle={title()}
      modalConfigsProps={{
        customContainerClassName: classNames(
          styles['term-and-conditions__container'],
          {
            [styles['term-and-conditions__container--desktop']]: !isMobile,
            [styles['term-and-conditions__container--mobile']]: isMobile
          }
        ),
        isOpen: isOpen,
        fullScreen: isMobile,
        minHeight: '564px',
        toggle: onClose,
        toggleOutside: onClose
      }}
    >
      {richTextData && <RichText richTextData={richTextData} />}
    </DesktopModalWrapper>
  )
}
