import { IHandleStatusText } from '../interfaces/IShelfList'

const statusRequestReceived = [
  'waiting-for-sellers-confirmation',
  'payment-pending',
  'payment-approved',
  'time-for-cancelation',
  'on_order_completed',
  'order_created',
  'approve_payment',
  'authorize-fulfillment',
  'new'
]

const orderStatus = {
  canceled: {
    text: 'Cancelada',
    color: 'error',
    customColor: '--color-feedback-error'
  },
  cancelling: {
    text: 'Cancelación en proceso',
    color: 'error',
    customColor: '--color-feedback-error'
  },
  invoiced: {
    text: 'En preparación',
    color: 'warning',
    customColor: '--color-icons-warning'
  },
  'ready-for-handling': {
    text: 'Confirmada',
    color: 'warning',
    customColor: '--color-icons-warning'
  },
  incomplete: {
    text: 'Rechazada',
    color: 'error',
    customColor: '--color-feedback-error'
  },
  'payment-denied': {
    text: 'Rechazada',
    color: 'error',
    customColor: '--color-feedback-error'
  },
  handling: {
    text: 'En preparación',
    color: 'warning',
    customColor: '--color-icons-warning'
  },
  invoice: {
    text: 'En preparación',
    color: 'warning',
    customColor: '--color-icons-warning'
  },
  'delivered-fail': {
    text: 'En reprogramación',
    color: 'warning',
    customColor: '--color-icons-warning'
  }
}

const residentialStatus = {
  delivered: {
    text: 'Entregada',
    color: 'success',
    customColor: '--color-icons-success'
  },
  sent: {
    text: 'En camino',
    color: 'warning',
    customColor: '--color-icons-warning'
  }
}

const pickupStatus = {
  delivered: {
    text: 'Retirada',
    color: 'success',
    customColor: '--color-icons-success'
  },
  sent: {
    text: 'Lista para retiro',
    color: 'warning',
    customColor: '--color-icons-warning'
  }
}

export const requestRecived: IHandleStatusText = {
  text: 'Solicitud recibida',
  color: 'warning',
  customColor: '--color-icons-warning'
}

export const logicHandleStatus = (
  status: string,
  addressType: string
): IHandleStatusText => {
  if (
    (!residentialStatus[status.toLowerCase()] &&
      !pickupStatus[status.toLowerCase().toLocaleLowerCase()] &&
      !orderStatus[status.toLowerCase()]) ||
    statusRequestReceived.includes(status.toLowerCase())
  )
    return requestRecived
  if (orderStatus[status.toLowerCase()])
    return orderStatus[status.toLowerCase()]
  if (addressType === 'residential')
    return residentialStatus[status.toLowerCase()]
  return pickupStatus[status.toLowerCase()]
}
