
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { IHandleStatusTracking, IWebTracking } from 'shared/interfaces/IShipping'

interface IStatusRequestReceivedTracking{
  iconPicking: keyof typeof Icons
  icons: IWebTracking[]
  textPosition3: string
}

export const statusRequestReceivedTracking = ({
  iconPicking,
  icons,
  textPosition3
}:IStatusRequestReceivedTracking):IHandleStatusTracking[] => {

  return icons.map((icon: IHandleStatusTracking, position: number) => {
    if (position === 0) {
      return {
        ...icon,
        colorBackIcon: '--color-text-primary',
        colorBorder: '--color-text-primary',
        colorIcon: '--color-text-white',
        colorText: 'warning',
        customColorText:'--color-icons-warning'
      }
    }
    if (position === 3) {
      return {
        ...icon,
        nameIcon: iconPicking,
        text: textPosition3
      }
    }
    return icon
  })
}