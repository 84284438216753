import {
  ContentfulResponse,
  IContentfulResponse
} from '@smu-chile/pkg-unimarc-hooks'
import { ConnectionCfSaving } from 'shared/utils/connectionCfSaving'

export const variableBreakdownCfSaving = () => {
  const { dataSavingCF } = ConnectionCfSaving()
  const cfResponse = new ContentfulResponse(dataSavingCF as IContentfulResponse)
  const cfPopulate = cfResponse.populateEntries().getResponse()

  const [saving] = cfPopulate?.items ?? []

  const picture = mapBanner(saving)

  return {
    picture
  }
}

export const mapBanner = (item?: unknown) => {
  return {
    id: item?.['sys']?.['id'] ?? 'no-id',
    pictureSrc: item?.['fields']?.['file']?.['url'] ?? null
  }
}
