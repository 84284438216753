/**
 * Verify if the value exist and get them from localStorage
 * @param value item to search in localStorage
 * @param type type of value
 * @returns found item in localStorage
 */
export const getLocalStorageItem = (value: string, type: string | number = 'string'): string | number => {
  if (typeof window === 'undefined') return ''
  if(type === 'number') return Number(window.localStorage.getItem(value))
  return window.localStorage.getItem(value)
}