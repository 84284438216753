import {
  IOptiornsEntriesRequest,
  useContentful
} from '@smu-chile/pkg-unimarc-hooks'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'
import { IOrders } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrders'
import { useEffect, useState } from 'react'

type ContentfulOptions = {
  id_contentful: string
  options?: IOptiornsEntriesRequest
  type?: string
}

const useContentfulData = ({
  id_contentful,
  options,
  type
}: ContentfulOptions) => {
  const [data, setData] = useState(null)
  const {
    isLoading,
    error,
    data: contentfulData
  } = useContentful({
    id_contentful,
    options,
    type
  })

  useEffect(() => {
    if (!isLoading && !error && contentfulData) {
      setData(contentfulData)
    }
  }, [isLoading, error, contentfulData])

  return { isLoading, error, data }
}

export default useContentfulData

/* export const statusPrePicking = [
  'Cancelada',
  'Solicitud recibida',
  'Confirmada',
  'Rechazada'
] */

export const statusCancelling = [
  'cancelling',
  'canceled',
  'incomplete',
  'returned-total-pending',
  'returned-total',
  'payment-denied'
]

export const orderIconTheme = (
  order: IOrders | Order,
  srcCancel: string,
  srcMoto: string,
  srcStore: string
): string => {
  const { status, addressType } = order
  if (statusCancelling.includes(status)) return srcCancel

  if (addressType === 'pickup') {
    return srcStore
  }
  return srcMoto
}
