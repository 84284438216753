export const getBase64 = (file: Blob): Promise<string | ArrayBuffer> => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}
