import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import {
  postCoordsValidate,
  useContentful,
  UseQueryResult
} from '@smu-chile/pkg-unimarc-hooks'
import { Backbone } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
interface MapProps {
  location: {
    latMap: number
    lngMap: number
  }
  coordsError: (error?: string, type?: string) => void
  setNewLocation: (lat: number, lng: number) => void
}

interface ContentfulLocalPinAsset {
  fields: {
    file: {
      url: string
    }
    title: string
  }
}

interface UseContentfulLocalPinResult {
  items: ContentfulLocalPinAsset[]
}

export const Map = ({
  location,
  coordsError,
  setNewLocation
}: MapProps): React.ReactElement => {
  const { data } = useContentful({
    environment: 'master',
    options: {
      'fields.title[in]': 'unimarcSLPin'
    },
    reactQuery: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false
    },
    type: 'assets'
  }) as UseQueryResult<UseContentfulLocalPinResult>

  const pinMap = data?.items?.filter((item) => {
    return item.fields.title == 'unimarcSLPin'
  })[0]?.fields?.file

  const pin = async (coords) => {
    const coordsVal = await postCoordsValidate({
      data: [coords.lng(), coords.lat()]
    })
    setNewLocation(coords.lat(), coords.lng())
    if (coordsVal.data.error === true) {
      return coordsError(
        'Lo sentimos, tuvimos un error al cargar tu información por favor intenta de nuevo.',
        'error'
      )
    }
    if (coordsVal.message !== 'OK') {
      return coordsError(
        'Lo sentimos, no existe cobertura para esta dirección.',
        'message'
      )
    }

    return coordsError()
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY
  })

  if (!isLoaded) {
    return (
      <Backbone
        backgroundColor={getGlobalStyle('--color-loaders-background')}
        borderRadius={null}
        foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
        height={224}
        speed={3}
        type='Generic'
        width={500}
      />
    )
  }

  return (
    <GoogleMap
      center={{
        lat: location.latMap,
        lng: location.lngMap
      }}
      mapContainerStyle={{
        width: '100%',
        height: '224px'
      }}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      }}
      zoom={15}
    >
      <Marker
        draggable
        icon={pinMap}
        onDragEnd={(mapCoords) => {
          return pin(mapCoords.latLng)
        }}
        position={{
          lat: location.latMap,
          lng: location.lngMap
        }}
      />
    </GoogleMap>
  )
}
