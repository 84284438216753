import { IOrders } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrders'
import { IHandleStatusText } from '../interfaces/IShelfList'
import { logicHandleStatus } from './logicHandleStatus'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

export const handleStatusTextOrdersList = (
  order: IOrders | Order
): IHandleStatusText => {
  const { addressType, status } = order
  return logicHandleStatus(status, addressType)
}
