import { IEntriesProps, useContentful } from '@smu-chile/pkg-unimarc-hooks'

interface IConnectionCfBenefits {
  dataBenefitsCF?: IEntriesProps
}

const ConnectionCfBenefits = ({
  include
}: { include?: number } = {}): IConnectionCfBenefits => {
  const { data: dataBenefitsCF } = useContentful({
    id_contentful: `Club-Benefits-${include}`,
    options: {
      content_type: 'featureContentBanners',
      'fields.label[in]': 'Beneficios por nivel mobile',
      include
    },
    reactQuery: {
      enabled: true,
      retry: 3,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  })

  return {
    dataBenefitsCF
  }
}

export { ConnectionCfBenefits }
