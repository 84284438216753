import { IEntriesProps, useContentful } from '@smu-chile/pkg-unimarc-hooks'

interface IConnectionCfSaving {
  dataSavingCF?: IEntriesProps
}

const ConnectionCfSaving = (): IConnectionCfSaving => {
  const { data: dataSavingCF } = useContentful({
    id_contentful: `Club-Saving-Asset`,
    type: 'assets',
    options: {
      'fields.title[in]': 'Membresia ahorro'
    },
    reactQuery: {
      enabled: true,
      retry: 3,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  })

  return {
    dataSavingCF
  }
}

export { ConnectionCfSaving }
