/**
 * Verify if the value exist and get them from sessionStorage
 * @param value item to search in sessionStorage
 * @param type type of value
 * @returns found item in sessionStorage
 */
export const getSessionStorageItem = (value: string, type: string | number = 'string'): string | number => {
  if (typeof window === 'undefined') return ''
  if(type === 'number') return Number(window.sessionStorage.getItem(value))
  return window.sessionStorage.getItem(value)
}