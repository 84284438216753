import {
  BannerCard,
  Column,
  MembershipCard,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  trigger,
  useSession,
  useUserSavings,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { variableBreakdownCfMembership } from '../helper/variableBreakdownCfMembership'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

export const HeaderMembership = () => {
  const session = useSession()
  const userSavings = useUserSavings({
    reactQuery: {
      enabled: session.isLoggedIn,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })
  const userWithMembership = useUserWithMembership({
    enabled: true,
    retry: 3,
    staleTime: FIVE_MINUTES_OUTDATED_INFO,
    refetchOnWindowFocus: false
  })

  const handleBannerButtonClick = () => {
    trigger({
      eventType: 'openMembershipModal',
      data: { eventContext: 'Mi cuenta' }
    })
  }

  const handleBenefitsClick = () => {
    trigger({ eventType: 'openClubBenefitsModal' })
  }

  const handleSavingButtonClick = () => {
    trigger({ eventType: 'openClubSavingModal' })
  }

  const {
    actions,
    backgroundColor,
    banners,
    firstName,
    fontColor,
    pictureSrc,
    level
  } = variableBreakdownCfMembership({
    onBenefitsClick: handleBenefitsClick,
    onSavingClick: handleSavingButtonClick,
    userSavingsTotal: userSavings.data?.totalSavings ?? 0,
    userWithMembership: userWithMembership.data
  })

  return (
    <>
      {session.isLoggedIn && (
        <Column>
          <Spacer.Horizontal size={1} />

          <MembershipCard
            actions={actions}
            backgroundColor={backgroundColor}
            isLoading={userSavings.isLoading || userWithMembership.isLoading}
            pictureSrc={pictureSrc}
          >
            <Column
              alignItems='center'
              padding='0 2rem'
            >
              <Spacer.Horizontal customSize={11} />

              <Column alignItems='center'>
                <Text
                  customColor={fontColor}
                  fontSize='2xl'
                  fontWeight='light'
                  lineHeight='25px'
                  textAlign='center'
                >
                  Hola {firstName}
                </Text>

                <Spacer.Horizontal customSize={3} />

                <Text
                  customColor={fontColor}
                  fontSize='xl'
                  fontWeight='semibold'
                  lineHeight='23px'
                  textAlign='center'
                >
                  {level}
                </Text>
              </Column>

              <Spacer.Horizontal customSize={22} />
            </Column>
          </MembershipCard>

          {isValidArrayWithData(banners) && (
            <Column>
              <Spacer.Horizontal size={16} />

              {banners.map((banner) => {
                return (
                  <BannerCard
                    buttonLabel={banner.buttonLabel}
                    key={banner.id}
                    label={banner.label}
                    labelBackgroundColor={banner.labelBackgroundColor}
                    labelFontColor={banner.labelFontColor}
                    labelPictureSrc={banner.pictureSrc}
                    onButtonClick={handleBannerButtonClick}
                    subtitle={banner.subtitle}
                    title={banner.title}
                  />
                )
              })}
            </Column>
          )}

          <Spacer.Horizontal size={32} />
        </Column>
      )}
    </>
  )
}
