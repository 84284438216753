import {
  Column,
  Container,
  MobileModalWrapper,
  Picture,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  currencyFormat,
  useEvents,
  useUserSavings
} from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { variableBreakdownCfSaving } from '../helper/variableBreakdownCfSaving'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

export const HeaderClubSavingModal = () => {
  const router = useRouter()
  const [show, setShow] = useState(false)

  const userSavings = useUserSavings({
    reactQuery: {
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const userTotalSavings = useMemo(() => {
    if (userSavings.data) {
      return currencyFormat({
        number: userSavings.data?.totalSavings ?? 0,
        bool: false
      })
    }

    return '$-'
  }, [userSavings.data])

  const handleApply = () => {
    router.replace(`${process.env.NEXT_PUBLIC_HOMEURL}/club-unimarc/beneficios`)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleOpen = () => {
    setShow(true)
  }

  useEvents({
    eventType: 'closeClubSavingModal',
    callBack: () => {
      handleClose()
    }
  })

  useEvents({
    eventType: 'openClubSavingModal',
    callBack: () => {
      handleOpen()
    }
  })

  const { picture } = variableBreakdownCfSaving()

  return (
    <MobileModalWrapper
      blockId={getBemId('header', 'saving-modal')}
      body={
        <Container
          alignItems='start'
          customHeight='100%'
        >
          <Column alignItems='center'>
            <Container
              alignItems='center'
              customHeight='57px'
              justifyContent='center'
            >
              <Text
                fontSize='2xl'
                fontWeight='semibold'
              >
                ¡Felicidades!
              </Text>
            </Container>

            <Spacer.Horizontal size={24} />

            <Picture
              alt='Club Unimarc'
              height='246'
              src={picture.pictureSrc}
              width='246'
            />

            <Spacer.Horizontal size={24} />

            <Container
              alignItems='center'
              justifyContent='center'
              maxWidth='265px'
            >
              <Text
                fontWeight='regular'
                textAlign='center'
              >
                Haz ahorrado {userTotalSavings} en tus compras realizadas en las
                tiendas de Unimarc, app y web.
              </Text>
            </Container>
          </Column>
        </Container>
      }
      buttonLabel='Ver panoramas'
      dragProps={{
        isDraggable: false
      }}
      draggButton={false}
      footerContainerProps={{
        padding: '16px 16px 34px 16px'
      }}
      hasShadow={false}
      headerCloseIconProps={{
        name: 'CloseThin',
        sizes: '2xs'
      }}
      hideHeaderDivider={false}
      iconSize={0}
      isEnableButton={true}
      modalConfigsProps={{
        isAutoHeight: false,
        isOpen: show,
        toggle: handleClose,
        toggleOutside: handleClose,
        minHeightFullScreen: 'calc(100vh - 220px)',
        maxHeightFullScreen: '590px'
      }}
      onApply={handleApply}
      onClose={handleClose}
    />
  )
}
