import { datalayerSimpleEvent, sleep } from '@smu-chile/pkg-unimarc-hooks'
import { NextRouter } from 'next/router'

// TODO: refactor type to make Promise<void> in Header component
export const goToMyOrders = async (router: NextRouter): Promise<void> => {
  datalayerSimpleEvent({
    event: 'evento-interactivo',
    eventAction: 'Botón seguimiento de tu pedido',
    eventCategory: 'Interacciones en compra exitosa',
    eventLabel: 'Seguimiento de tu pedido'
  })
  await sleep(1000)
  if(window.location.origin === process.env.NEXT_PUBLIC_MYORDERSURL) window.location.reload() 
  router.push(process.env.NEXT_PUBLIC_MYORDERSURL)
}