import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { IHandleStatusTracking } from 'shared/interfaces/IShipping'

interface IMappingStatusTracking {
  iconPicking: keyof typeof Icons
  textPosition3: string
  textPosition4: string
}

interface IMappingStatus {
  [key: string]: { [key: number]: IHandleStatusTracking }
}

const defaultStatusTracking: IHandleStatusTracking = {
  colorBackIcon: '--color-text-primary',
  colorBorder: '--color-text-primary',
  colorIcon: '--color-text-white',
  colorText: 'warning',
  customColorText:'--color-icons-warning'
}

const defaultCanceledTracking: IHandleStatusTracking = {
  colorBackIcon: '--color-text-white',
  colorBorder: '--color-text-inactive',
  colorIcon: '--color-text-inactive',
  colorText: 'inactive',
  customColorText: '--color-text-inactive',
  text: ''
}

export const mappingStatusTracking = ({
  iconPicking,
  textPosition3,
  textPosition4
}: IMappingStatusTracking): IMappingStatus => {

  const handleCancellingAndIncompleteStatus = (status: string): IMappingStatus => {
    const properties = {
      0: {
        colorText: 'gray',
        colorBackIcon: '--color-text-white',
        colorIcon: '--color-text-gray-light',
        customColorText: '--color-text-gray-light',
        text: 'Solicitud Recibida'
      },
      1: {
        ...defaultStatusTracking,
        colorText: 'error',
        customColorText:'--color-feedback-error',
        nameIcon: 'CloseVariant2',
        text: status === 'cancelling' ? 'Cancelación en proceso' : 'Rechazada'
      },
      2: {
        ...defaultCanceledTracking,
        text: 'En preparación'
      },
      3: {
        ...defaultCanceledTracking,
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        ...defaultCanceledTracking,
        nameIcon: 'BagTick',
        text: textPosition4
      }
    }
    return properties
  }

  const handleInvoicedAndHandlingStatus = (): IMappingStatus => {
    const properties = {
      2: {
        ...defaultStatusTracking
      },
      3: {
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        nameIcon: 'BagTick',
        text: textPosition4
      }
    }
    return properties
  }

  return {
    'canceled': {
      0: {
        colorText: 'gray',
        colorBackIcon: '--color-text-white',
        colorIcon: '--color-text-gray-light',
        customColorText:'--color-text-gray-light',
        text: 'Solicitud Recibida'
      },
      1: {
        ...defaultStatusTracking,
        colorText: 'error',
        customColorText:'--color-feedback-error',
        nameIcon: 'CloseVariant2',
        text: 'Cancelada'
      },
      2: {
        ...defaultCanceledTracking,
        text: 'En preparación'
      },
      3: {
        ...defaultCanceledTracking,
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        ...defaultCanceledTracking,
        nameIcon: 'BagTick',
        text: textPosition4
      }
    },
    'cancelling': handleCancellingAndIncompleteStatus('cancelling'),
    'invoiced': handleInvoicedAndHandlingStatus(),
    'invoice': handleInvoicedAndHandlingStatus(),
    'ready-for-handling': {
      1: {
        ...defaultStatusTracking
      },
      3: {
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        nameIcon: 'BagTick',
        text: textPosition4
      }
    },
    'delivered': {
      3: {
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        ...defaultStatusTracking,
        colorText: 'success',
        customColorText:'--color-icons-success',
        nameIcon: 'BagTick',
        text: textPosition4
      }
    },
    'sent': {
      3: {
        ...defaultStatusTracking,
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        nameIcon: 'BagTick',
        text: textPosition4
      }
    },
    'incomplete': handleCancellingAndIncompleteStatus('incomplete'),
    'handling': handleInvoicedAndHandlingStatus(),
    'default': {
      0:{
        ...defaultStatusTracking
      },
      3: {
        nameIcon: iconPicking,
        text: textPosition3
      },
      4: {
        nameIcon: 'BagTick',
        text: textPosition4
      }
    }
  }
}
