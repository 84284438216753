import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'
import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { IWebTracking } from '../interfaces/IShipping'
import { changeStatus } from './changeStatus'

export const handleStatusTracking = (order: IOrder): IWebTracking[] => {
  const colorIcons = {
    colorBackIcon: '--color-base-white' as TTokens,
    colorBorder: '--color-text-gray-light' as TTokens,
    colorIcon: '--color-neutral-gray-dark' as TTokens,
    colorText: 'gray',
    customColorText: '--color-text-gray-light ' as TTokens
  }

  const icons = [
    {
      nameIcon: 'Book',
      text: 'Solicitud recibida'
    },
    {
      nameIcon: 'CardTicket',
      text: 'Confirmada'
    },
    {
      nameIcon: 'Box',
      text: 'En preparación'
    },
    {
      nameIcon: 'Motorcycle',
      text: 'En camino'
    },
    {
      nameIcon: 'BagTick',
      text: 'Entregada'
    }
  ].map((icon) => {
    return {
      ...icon,
      ...colorIcons
    }
  })

  return changeStatus(icons, order)
}
