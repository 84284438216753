import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { ConnectionCfHeaderMenu } from '../../../shared/utils/connectionCfHeaderMenu'
import { MENU_HEADER_MENU_BUTTONS } from 'shared/constants'

export interface IVariableBreakdownCF {
  cfDataHeader?: object
}

export const variableBreakdownCF = (): IVariableBreakdownCF => {
  const { dataHeaderCF } = ConnectionCfHeaderMenu()

  const buttonMyShopping = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[0].toString()
  })
  const buttonMyList = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[1].toString()
  })
  const buttonMyCards = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[2].toString()
  })
  const buttonSingOff = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[3].toString()
  })
  const buttonCoupon = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[4].toString()
  })
  const buttonPersonalInformation = dataHeaderCF?.includes?.Entry?.find(
    (data) => {
      return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[5].toString()
    }
  )
  const buttonHelpCenter = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[6].toString()
  })
  const buttonMembership = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[7].toString()
  })

  const buttonMyRequests = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[8].toString()
  })

  const buttonMyFavorites = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_HEADER_MENU_BUTTONS[9].toString()
  })

  const backgroundLabelCoupon = buttonCoupon?.fields?.labelColorBackground
  const backgroundLabelHelpCenter =
    buttonHelpCenter?.fields?.labelColorBackground
  const backgroundLabelMembership =
    buttonMembership?.fields?.labelColorBackground
  const backgroundLabelMyCards = buttonMyCards?.fields?.labelColorBackground
  const backgroundLabelMyList = buttonMyList?.fields?.labelColorBackground
  const backgroundLabelMyRequests =
    buttonMyRequests?.fields?.labelColorBackground
  const backgroundLabelMyShopping =
    buttonMyShopping?.fields?.labelColorBackground
  const backgroundLabelPersonalInformation =
    buttonPersonalInformation?.fields?.labelColorBackground
  const backgroundLabelSingOff = buttonSingOff?.fields?.labelColorBackground
  const backgroundLabelMyFavorites =
    buttonMyFavorites?.fields?.labelColorBackground

  const colorLabelCoupon = buttonCoupon?.fields?.labelColorText
  const colorLabelHelpCenter = buttonHelpCenter?.fields?.labelColorText
  const colorLabelMembership = buttonMembership?.fields?.labelColorText
  const colorLabelMyCards = buttonMyCards?.fields?.labelColorText
  const colorLabelMyList = buttonMyList?.fields?.labelColorText
  const colorLabelMyRequests = buttonMyRequests?.fields?.labelColorText
  const colorLabelMyShopping = buttonMyShopping?.fields?.labelColorText
  const colorLabelPersonalInformation =
    buttonPersonalInformation?.fields?.labelColorText
  const colorLabelSingOff = buttonSingOff?.fields?.labelColorText
  const colorLabelMyFavorites = buttonMyFavorites?.fields?.labelColorText

  const iconCoupon = buttonCoupon?.fields?.iconText as keyof typeof Icons
  const iconHelpCenter = buttonHelpCenter?.fields
    ?.iconText as keyof typeof Icons
  const iconMembership = buttonMembership?.fields
    ?.iconText as keyof typeof Icons
  const iconMyCards = buttonMyCards?.fields?.iconText as keyof typeof Icons
  const iconMyList = buttonMyList?.fields?.iconText as keyof typeof Icons
  const iconMyRequests = buttonMyRequests?.fields
    ?.iconText as keyof typeof Icons
  const iconMyShopping = buttonMyShopping?.fields
    ?.iconText as keyof typeof Icons
  const iconPersonalInformation = buttonPersonalInformation?.fields
    ?.iconText as keyof typeof Icons
  const iconSingOff = buttonSingOff?.fields?.iconText as keyof typeof Icons
  const iconMyFavorites = buttonMyFavorites?.fields
    ?.iconText as keyof typeof Icons

  const labelCoupon = buttonCoupon?.fields?.label
  const labelHelpCenter = buttonHelpCenter?.fields?.label
  const labelMembership = buttonMembership?.fields?.label
  const labelMyCards = buttonMyCards?.fields?.label
  const labelMyList = buttonMyList?.fields?.label
  const labelMyRequests = buttonMyRequests?.fields?.label
  const labelMyShopping = buttonMyShopping?.fields?.label
  const labelPersonalInformation = buttonPersonalInformation?.fields?.label
  const labelSingOff = buttonSingOff?.fields?.label
  const labelMyFavorites = buttonMyFavorites?.fields?.label

  const showBtnCoupon = buttonCoupon?.fields?.showbuttom
  const showBtnHelpCenter = buttonHelpCenter?.fields?.showbuttom
  const showBtnMembership = buttonMembership?.fields?.showbuttom
  const showBtnMyCards = buttonMyCards?.fields?.showbuttom
  const showBtnMyList = buttonMyList?.fields?.showbuttom
  const showBtnMyRequests = buttonMyRequests?.fields?.showbuttom
  const showBtnMyShopping = buttonMyShopping?.fields?.showbuttom
  const showBtnPersonalInformation =
    buttonPersonalInformation?.fields?.showbuttom
  const showBtnSingOff = buttonSingOff?.fields?.showbuttom
  const showBtnMyFavorites = buttonMyFavorites?.fields?.showbuttom

  const showLabelCoupon = buttonCoupon?.fields?.showLabel
  const showLabelHelpCenter = buttonHelpCenter?.fields?.showLabel
  const showLabelMembership = buttonMembership?.fields?.showLabel
  const showLabelMyCards = buttonMyCards?.fields?.showLabel
  const showLabelMyList = buttonMyList?.fields?.showLabel
  const showLabelMyRequests = buttonMyRequests?.fields?.showLabel
  const showLabelMyShopping = buttonMyShopping?.fields?.showLabel
  const showLabelPersonalInformation =
    buttonPersonalInformation?.fields?.showLabel
  const showLabelSingOff = buttonSingOff?.fields?.showLabel
  const showLabelMyFavorites = buttonMyFavorites?.fields?.showLabel

  const textLabelCoupon = buttonCoupon?.fields?.labelNewText
  const textLabelHelpCenter = buttonHelpCenter?.fields?.labelNewText
  const textLabelMembership = buttonMembership?.fields?.labelNewText
  const textLabelMyCards = buttonMyCards?.fields?.labelNewText
  const textLabelMyList = buttonMyList?.fields?.labelNewText
  const textLabelMyRequests = buttonMyRequests?.fields?.labelNewText
  const textLabelMyShopping = buttonMyShopping?.fields?.labelNewText
  const textLabelPersonalInformation =
    buttonPersonalInformation?.fields?.labelNewText
  const textLabelSingOff = buttonSingOff?.fields?.labelNewText
  const textLabelMyFavorites = buttonMyFavorites?.fields?.labelNewText

  const cfDataHeader = {
    backgroundLabelCoupon,
    backgroundLabelHelpCenter,
    backgroundLabelMembership,
    backgroundLabelMyCards,
    backgroundLabelMyFavorites,
    backgroundLabelMyList,
    backgroundLabelMyRequests,
    backgroundLabelMyShopping,
    backgroundLabelPersonalInformation,
    backgroundLabelSingOff,
    colorLabelCoupon,
    colorLabelHelpCenter,
    colorLabelMembership,
    colorLabelMyCards,
    colorLabelMyFavorites,
    colorLabelMyList,
    colorLabelMyRequests,
    colorLabelMyShopping,
    colorLabelPersonalInformation,
    colorLabelSingOff,
    iconCoupon,
    iconHelpCenter,
    iconMembership,
    iconMyCards,
    iconMyFavorites,
    iconMyList,
    iconMyRequests,
    iconMyShopping,
    iconPersonalInformation,
    iconSingOff,
    labelCoupon,
    labelHelpCenter,
    labelMembership,
    labelMyCards,
    labelMyFavorites,
    labelMyList,
    labelMyRequests,
    labelMyShopping,
    labelPersonalInformation,
    labelSingOff,
    showBtnCoupon,
    showBtnHelpCenter,
    showBtnMembership,
    showBtnMyCards,
    showBtnMyFavorites,
    showBtnMyList,
    showBtnMyRequests,
    showBtnMyShopping,
    showBtnPersonalInformation,
    showBtnSingOff,
    showLabelCoupon,
    showLabelHelpCenter,
    showLabelMembership,
    showLabelMyCards,
    showLabelMyFavorites,
    showLabelMyList,
    showLabelMyRequests,
    showLabelMyShopping,
    showLabelPersonalInformation,
    showLabelSingOff,
    textLabelCoupon,
    textLabelHelpCenter,
    textLabelMembership,
    textLabelMyCards,
    textLabelMyFavorites,
    textLabelMyList,
    textLabelMyRequests,
    textLabelMyShopping,
    textLabelPersonalInformation,
    textLabelSingOff
  }

  return {
    cfDataHeader
  }
}
