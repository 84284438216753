import { IProductInList } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ILists'

/**
 * Validate if an element exists in an array, then get the quantity of the product & filter the quantitys we don't want.
 * @param evaluateId the id of the product you want to find in the array.
 * @param array the array that will we iterated over.
 * @returns desired quantity
 */

export const getQuantityOfProducts = (evaluateId: string, array: Array<IProductInList>) => {
  const rawQuantity = array?.map((product) => {return product.id === evaluateId ? product.quantity : 0})
  const finalQuantity = rawQuantity?.filter(quantitys => {return quantitys !== 0})
  return finalQuantity
}