import { ItemFields } from '@smu-chile/pkg-unimarc-hooks'
import { MEMBERSHIP_STATUS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

// To identify where the event is coming from
type IEventType = 'open' | 'button' | 'toast'

/**
 * Get event action and label based on event type and selected plan.
 *
 * @param {IEventType} type - The type of event.
 * @param {ItemFields} planSelected - The selected plan.
 * @returns {Object} An object containing eventAction and eventLabel.
 */
const getEventActionAndLabel = (
  type: IEventType,
  planSelected: ItemFields,
  status: string
) => {
  const isInactiveOrExpired =
    status === MEMBERSHIP_STATUS.INACTIVE ||
    status === MEMBERSHIP_STATUS.EXPIRED

  if (type === 'toast') {
    return {
      eventAction: isInactiveOrExpired
        ? 'error_renovar_membresia'
        : 'error_confirmar_membresia',
      eventLabel: planSelected?.subtitulo ?? ''
    }
  } else if (type === 'button') {
    return {
      eventAction: isInactiveOrExpired
        ? 'clic_renovar_membresia'
        : 'clic_confirmar_membresia',
      eventLabel: planSelected?.subtitulo ?? ''
    }
  }

  // when open modal
  return {
    eventAction: 'apertura_modal',
    eventLabel: isInactiveOrExpired
      ? 'vista_modal_renovacion'
      : 'vista_modal_contratacion'
  }
}

/**
 * Create data layer object for membership events.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.status - The membership status.
 * @param {string} params.eventContext - The event context.
 * @param {ItemFields} params.planSelected - The selected plan.
 * @param {IEventType} params.type - The type of event.
 * @returns {Object} The data layer object.
 */
export const dataLayerMembership = ({
  status,
  eventContext,
  planSelected,
  type
}) => {
  const isInactiveOrExpired =
    status === MEMBERSHIP_STATUS.INACTIVE ||
    status === MEMBERSHIP_STATUS.EXPIRED

  const eventCategory = isInactiveOrExpired
    ? 'renovar_membresia'
    : 'mi_membresia'

  const result = getEventActionAndLabel(type, planSelected, status)

  return {
    event: 'evento_interactivo',
    eventAction: result.eventAction,
    eventCategory,
    eventLabel: result.eventLabel,
    context: eventContext
  }
}
