const addressFormat = (addressData) => {
  const formated = {
    city: '',
    country: '',
    neighborhood: '',
    number: '',
    place_id: addressData[0].place_id,
    postalCode: '',
    state: '',
    street: ''
  }
  const findTypes = (types, filter) => {
    return types.indexOf(filter) > -1
  }
  addressData[0]?.address_components?.map((addressParts) => {

    if (findTypes(addressParts.types, 'street_number')) {
      formated.number = addressParts.long_name
    }
    if (findTypes(addressParts.types, 'route')) {
      formated.street = addressParts.short_name
    }

    if (findTypes(addressParts.types, 'locality') || findTypes(addressParts.types, 'administrative_area_level_3')) {
      formated.neighborhood = addressParts.long_name
    }
    if (findTypes(addressParts.types, 'administrative_area_level_1')) {
      formated.state = addressParts.long_name
    }
    if (findTypes(addressParts.types, 'administrative_area_level_2')) {
      formated.city = addressParts.long_name
    }
    if (findTypes(addressParts.types, 'country')) {
      formated.country = 'CHL'
    }

    if (findTypes(addressParts.types, 'postal_code')) {
      formated.postalCode = addressParts.long_name
    }
  })
  return formated
}

export { addressFormat }