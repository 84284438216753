import { IHandleStatusTracking, IWebTracking } from '../interfaces/IShipping'
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { ShippingData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { mappingStatusTracking } from './mappingStatus'

interface ISwitchStatusTracking {
  icons: IWebTracking[]
  status: string
  shippingData: ShippingData
  iconPicking: keyof typeof Icons
  textPosition3: string
}

export const switchStatusTracking = ({
  iconPicking,
  icons,
  shippingData,
  status,
  textPosition3
}: ISwitchStatusTracking): IHandleStatusTracking[] => {

  const textPosition4 = shippingData?.address?.addressType === 'residential' ? 'Entregada' : 'Retirada'
  const mappingStatus = mappingStatusTracking({
    iconPicking,
    textPosition3,
    textPosition4
  })

  const searchMappingStatus = mappingStatus[status.toLowerCase()] ? status.toLowerCase() : 'default'

  return icons.map((icon: IHandleStatusTracking, position: number) => {
    if (mappingStatus[searchMappingStatus][position]) {
      return {
        ...icon,
        ...mappingStatus[searchMappingStatus][position]
      }
    }
    return icon
  })

}