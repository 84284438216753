const keyStorage = 'RELOAD_ATTEMPTS_COUNTER'

export const initStorage = () => { return !getAttempts() && localStorage.setItem(keyStorage, '0') }

export const getAttempts = () => { return localStorage.getItem(keyStorage) }

export const addAttempt = () => { return localStorage.setItem(keyStorage, `${Number(getAttempts()) + 1}`) }

export const restartAttempt = () => { return getAttempts() && Number(getAttempts()) > 0 &&localStorage.setItem(keyStorage, '0') }

