import { IEntriesProps, useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { ID_HEADER_MENU_BUTTONS } from 'shared/constants'

interface IConnectionCfHeaderMenu {
  dataHeaderCF?: IEntriesProps
}

type ConnectionCfHeaderMenuProps = {
  include?: number
}

const ConnectionCfHeaderMenu = ({
  include
}: ConnectionCfHeaderMenuProps = {}): IConnectionCfHeaderMenu => {
  const { data: dataHeaderCF } = useContentful({
    id_contentful: `CentroDeAyuda-HeaderMenu-${include}`,
    options: {
      'sys.contentType.sys.id[in]': 'viewBlock',
      'sys.id[in]': ID_HEADER_MENU_BUTTONS[0],
      include
    },
    reactQuery: {
      enabled: true,
      retry: 3,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  })

  return {
    dataHeaderCF
  }
}

export { ConnectionCfHeaderMenu }
